import router from "./route";
import store from "./store";
import { ElMessage } from "element-plus";
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style
import { ACCESS_TOKEN } from "@/store/mutation-types";
import { isNotEmpty } from "./utils/util";

NProgress.configure({ showSpinner: false }); // NProgress Configuration

const whiteList = ["/login"]; // no redirect whitelist

router.beforeEach(async (to, from, next) => {
    //console.log("router Before")
    // start progress bar
    NProgress.start();

    // determine whether the user has logged in
    const hasToken = sessionStorage.getItem(ACCESS_TOKEN);
    if (hasToken) {
        if (to.path === "/login") {
            // if is logged in, redirect to the home page
            next({ path: "/dashboard" });
        } else {
            next();
        }
    } else {
        /* has no token*/
        if (whiteList.indexOf(to.path) !== -1) {
            // in the free login whitelist, go directly
            next();
        } else {
            // other pages that do not have permission to access are redirected to the login page.
            next(`/login?redirect=${to.path}`);
        }
    }

    //判断keepalive页面是否重新加载页面
    const fromRouteName = from.name
    const toRouteName = to.name
    const toRouteId = to.query.id || ''
    const historyRouteId = store.getters.historyRouteId
    
    if(isNotEmpty(fromRouteName) && fromRouteName !== toRouteName){
        //路由切换了
        if(toRouteName === "dashboard"){
            const h_overviewRouteId = historyRouteId.overviewRouteId
    
            if(h_overviewRouteId !== toRouteId){
                to.meta.reloadPage = true
            }
        }
        if(toRouteName === "catalog_report"){
            const h_catalogsRouteId = historyRouteId.catalogsRouteId
    
            if(h_catalogsRouteId !== toRouteId){
                to.meta.reloadPage = true
            }
        }
    }
});

router.afterEach((to,from,next) => {
    // finish progress bar
    NProgress.done();
    const toRouteName = to.name
    const toRouteId = to.query.id || ''

    let historyRouteId = store.getters.historyRouteId
    if(toRouteName === "dashboard"){
        historyRouteId.overviewRouteId = toRouteId
    }
    if(toRouteName === "catalog_report"){
        historyRouteId.catalogsRouteId = toRouteId
    }
    store.dispatch("setHRoutIds",historyRouteId);
});
