/*
 * @Author: lifuhai
 * @Date: 2023-03-17 13:46:24
 * @LastEditTime: 2023-03-17 17:13:20
 * @LastEditors: lifuhai
 * @FilePath: /hmlg/src/utils/request.js
 * @Description: 
 */
import axios from "axios";
import store from "@/store";
import storage from "store";
import notification from "ant-design-vue/es/notification";
import { VueAxios } from "./axios";
import { ACCESS_TOKEN, LOGIN_IN_USERNAME } from "@/store/mutation-types";
import { ElMessageBox, ElMessage } from "element-plus";
import qs from "qs";

// 创建 axios 实例
const request = axios.create({
    // API 请求的默认前缀
    baseURL: "/api",
    withCredentials: true,
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    timeout: 600000, // 请求超时时间
});

// 异常拦截处理器
const errorHandler = (error) => {
    if (error.response) {
        const data = error.response.data;
        // 从 localstorage 获取 token
        const token = storage.get(ACCESS_TOKEN);
        if (error.response.status === 403) {
            notification.error({
                message: "Forbidden",
                description: data.message,
            });
        }
        if (
            error.response.status === 401 &&
            !(data.result && data.result.isLogin)
        ) {
            notification.error({
                message: "Unauthorized",
                description: "Authorization verification failed",
            });
            if (token) {
                store.dispatch("Logout").then(() => {
                    window.location.reload();
                });
            }
        }
    }
    return Promise.reject(error);
};

// request interceptor
request.interceptors.request.use((config) => {
    if (config.method === "post") {
        if (
            config.headers["Content-Type"] === "application/x-www-form-urlencoded"
        ) {
            config.data = { ...config.data, tmp: Math.random() };
            config.data = qs.stringify(config.data);
        }
    }
    const token = storage.get(ACCESS_TOKEN)
    // 如果 token 存在
    // 让每个请求携带自定义 token 请根据实际情况自行修改
    if (token) {
        config.headers['Access-Token'] = token
    }
    return config;
}, errorHandler);


// 响应拦截器
request.interceptors.response.use(
    response => {
        const res = response.data
        const code = res.code

        // if the custom code is not 20000, it is judged as an error.
        if (code !== 20000) {
            // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
            if (
                res.code === 50008 ||
                res.code === 50012 ||
                res.code === 50014
            ) {
                // to re-login
                ElMessageBox.alert(
                    'Session is invalid or expired, Please re-login!',
                    'logout',
                    {
                        confirmButtonText: 'Re-Login',
                        type: 'warning',
                    }
                ).then(() => {
                    sessionStorage.removeItem(ACCESS_TOKEN)
                    sessionStorage.removeItem(LOGIN_IN_USERNAME)
                    location.reload()
                }).catch((action) => {

                })
            } else {
                // error message dialog
                ElMessage({
                    message: res.message || 'Error',
                    type: 'error',
                    duration: 6 * 1000,
                })
            }

            return Promise.reject(new Error(res.message || 'Error'))
        } else {
            return {
                data: res.data,
                code: response.status,
                token: res.data.token,
                message: res.message,
                success: res.success,
            }
        }
    },
    error => {
        // 响应失败的回调函数
        return Promise.reject(new Error('failed') || 'Error')
    }
)

const installer = {
    vm: {},
    install(Vue) {
        Vue.use(VueAxios, request);
    },
};

export default request;

export { installer as VueAxios, request as axios };
