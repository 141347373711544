import { postAxios } from './main'

const userApi = {
    loginUrl: '/ecomApi/ecommerce/login/',
    logOutUrl: '/ecomApi/ecommerce/loginOut/',
}
export function login(parameter) {
    return postAxios(userApi.loginUrl, parameter)
}
export function logout() {
    return postAxios(userApi.logOutUrl)
}