const pageInfo = {
    state: {
        historyRouteId:{
            overviewRouteId:'',
            catalogsRouteId:''
        },
    },

    mutations: {
        SET_HISTORYROUTEID: (state, historyRouteId) => {
            state.historyRouteId = historyRouteId
        },
    },

    actions: {
        setHRoutIds({ commit }, historyRouteId) {
            commit('SET_HISTORYROUTEID', historyRouteId)
        },
    }
}

export default pageInfo
