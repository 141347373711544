import storage from 'store'
import { login, logout } from '@/api/login'
import { ACCESS_TOKEN } from '@/store/mutation-types'

const user = {
    state: {
        token: '',
        name: '',
        welcome: '',
        avatar: '',
        roles: [],
        info: {}
    },

    mutations: {
        SET_TOKEN: (state, token) => {
            state.token = token
        },
        SET_NAME: (state, { name, welcome }) => {
            state.name = name
            state.welcome = welcome
        },
        SET_AVATAR: (state, avatar) => {
            state.avatar = avatar
        },
        SET_ROLES: (state, roles) => {
            state.roles = roles
        },
        SET_INFO: (state, info) => {
            state.info = info
        }
    },

    actions: {
        // 登录
        Login({ commit }, userInfo) {
            return new Promise((resolve, reject) => {
                login(userInfo)
                    .then(res => {
                        const result = res
                        const token = res.token
                        storage.set(ACCESS_TOKEN, token, 2 * 60 * 60 * 1000)
                        commit('SET_TOKEN', token)
                        resolve(result)
                    }).catch(error => {
                        reject(error)
                    })
            })
        },

        // 登出
        Logout({ commit, state }) {
            return new Promise(resolve => {
                logout(state.token)
                    .then(() => {
                        commit('SET_TOKEN', '')
                        commit('SET_ROLES', [])
                        storage.remove(ACCESS_TOKEN)
                        storage.remove('ERPUserInfo')
                        resolve()
                    })
                    .catch(() => {
                        resolve()
                    })
                    .finally(() => { })
            })
        }
    }
}

export default user
