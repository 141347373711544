import request from '@/utils/request'

const main = {
    url: ''
}
/* application/x-www-form-urlencoded    url参数传递
multipart/form-data   大数据传递,可携带文件
content-type:application/json   原json多层次传递，spring后台用@RequestBody来接收 */

export function getAxios(urlType, parameter = {}) {
    return request({
        url: `${main.url}${urlType}`,
        method: 'get',
        data: parameter
    })
}

export function postAxios(urlType, parameter = {}) {
    return request({
        url: `${main.url}${urlType}`,
        method: 'post',
        data: parameter
    })
}

export function getAxiosJson(urlType, parameter) {
    return request({
        url: `${main.url}${urlType}`,
        method: 'get',
        headers: { 'Content-Type': 'application/json' },
        data: parameter
    })
}
export function postAxiosJson(urlType, parameter) {
    return request({
        url: `${main.url}${urlType}`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: parameter
    })
}
export function getAxiosFormData(urlType, parameter) {
    return request({
        url: `${main.url}${urlType}`,
        method: 'POST',
        headers: { 'Content-Type': 'multipart/form-data' },
        data: parameter,
    })
}
export function postAxiosFormData(urlType, parameter) {
    return request({
        url: `${main.url}${urlType}`,
        method: 'POST',
        headers: { 'Content-Type': 'multipart/form-data' },
        data: parameter,
    })
}