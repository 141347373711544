const getters = {
    token: (state) => {
        return state.user.token
    },
    name: (state) => {
        return state.user.name
    },
    welcome: (state) => {
        return state.user.welcome
    },
    avatar: (state) => {
        return state.user.avatar
    },
    roles: (state) => {
        return state.user.roles
    },
    info: (state) => {
        return state.user.info
    },
    historyRouteId: (state) => {
        return state.pageInfo.historyRouteId
    }

};
export default getters;
