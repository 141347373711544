import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faPhone, faUserCircle, faUser, faEnvelope, faWarehouse, faSackDollar, faCog } from "@fortawesome/free-solid-svg-icons";

library.add(faPhone);
library.add(faUserCircle);
library.add(faUser);
library.add(faEnvelope);
library.add(faWarehouse);
library.add(faSackDollar);
library.add(faCog);

export default FontAwesomeIcon;