import { createStore } from 'vuex'
import user from './modules/user'
import pageInfo from './modules/pageInfo'
import getters from './getters';

// 创建一个新的 store 实例
const store = createStore({
    namespaced: true,
    modules: {
        user,
        pageInfo,
    },
    getters,
})

export default store