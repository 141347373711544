import { createRouter, createWebHashHistory } from 'vue-router'
const routes = [
  {
    path: '/',
    redirect: 'dashboard',
    component: () => import('../layout'),
    children: [{
      name: "dashboard",
      path: "dashboard",
      component: () => import('../view/Dashboard'),
      meta: {
        title: 'Dashboard',
        keepAlive: true //设置页面是否需要使用缓存
      }
    }, {
      name: "setting",
      path: "setting",
      component: () => import('../view/DashboardSetting'),
      meta: {
        title: 'Setting'
      }
    }, {
      name: "setting_view",
      path: "setting_view",
      component: () => import('../view/DashboardSetting_view'),
      meta: {
        title: 'Setting View'
      }
    }, {
      name: "login_history",
      path: "login_history",
      component: () => import('../view/LoginHistory'),
      meta: {
        title: 'Login History'
      }
    }, {
      name: "catalog_report",
      path: "catalog_report",
      component: () => import('../view/CatalogReport'),
      meta: {
        title: 'Catalogs',
        keepAlive: true //设置页面是否需要使用缓存
      }
    }]
  },
  {
    path: '/login',
    name: "login",
    component: () => import('../view/Login')
  },
  {
    path: '/404',
    name: "404",
    component: () => import('../view/404')
  }, {
    path: '/:catchAll(.*)',  //不识别的path自动匹配404
    redirect: "/404"
  },
]
export default createRouter({
  history: createWebHashHistory(),
  routes
})