//引入lodash js工具库
import _ from 'lodash'

/**
* @description 三位分节法
* @param threeDigitStr 需要三位分节法字符串
* @param decimals：比较前，保留几位小数 默认0位
*/
export function NumericTothreeDigit(threeDigitNumeric, decimals) {
  if (isEmpty(threeDigitNumeric)) {
    return threeDigitNumeric;
  }

  if (isNotEmpty(decimals) && isInteger(decimals) && decimals >= 0) {
    threeDigitNumeric = parseFloat(threeDigitNumeric).toFixed(decimals)
  } else {
    threeDigitNumeric = threeDigitNumeric.toString();
  }

  //判断是否为负数
  let sign = ""
  if (parseFloat(threeDigitNumeric) < 0) {
    sign = "-"
    threeDigitNumeric = threeDigitNumeric.substring(1)
  }

  var t1, t2, type = true, value = threeDigitNumeric;
  if (threeDigitNumeric.indexOf(".") != -1) {
    var arr = threeDigitNumeric.split(".");
    t1 = arr[0].toString().split('');
    t2 = arr[1].toString().trim();
  } else {
    t1 = threeDigitNumeric.split('');

    type = false;
  }

  var result = [], counter = 0;
  for (var i = t1.length - 1; i >= 0; i--) {
    counter++;
    result.unshift(t1[i]);
    if ((counter % 3) == 0 && i != 0) {
      result.unshift(',');
    }
  }

  //type判断是否有小数
  if (type === true) {
    value = result.join('') + '.' + t2;
  } else {
    value = result.join('');
  }

  return sign + value;
}
/*
 * 用途：检查输入字符串是否符合数字格式 输入： s：字符串 返回： 如果通过验证返回true,否则返回false
 * 
 */
export function isNumber(s) {
  let s_to = _.toNumber(s)
  return _.isNumber(s_to) && (_.isNaN(s_to) == false)
}

/*
 * 用途：输入字符串to数字格式 输入： s：字符串 返回： number
 * 
 */
export function toNumber(s) {
  let s_to = _.toNumber(s)
  return s_to
}

/*
 * 用途：检查输入对象的值是否符合整数格式 输入：str 输入的字符串 返回：如果通过验证返回true,否则返回false
 * 
 */
function isInteger(str) {
  var regu = /^[-]{0,1}[0-9]{1,}$/;
  return regu.test(str);
}

export function isEmpty(obj) {
  if (typeof obj == "undefined" || obj == null || obj === "" || obj.length == 0 || obj == "undefined") {
    return true;
  } else {
    return false;
  }
}

export function isNotEmpty(obj) {
  return !isEmpty(obj);
}

export function cloneObject(obj) {
  return _.clone(obj);
}
export function cloneDeepObject(obj) {
  return _.cloneDeep(obj)
}
export function collectionOrderBy(collection, identity, orders) {
  return _.orderBy(collection, identity, orders)
}
//全局字符串切分过滤器
export function filter_subString(value) {
  return value.substring(0, 5)
}

//全局日期字符串格式化
export function datefmt(input, fmtstring) {
  if (isEmpty(input)) {
    return input
  } else {
    return moment(input, 'YYYY/MM/DD HH:mm:ss').format(fmtstring)
  }
}

//全局Boolean字符串格式化
export function booleanfmt(input) {
  if (input === '' || typeof input === 'undefine') {
    return input
  } else {
    return Boolean(input) ? 'YES' : 'NO'
  }
}

//全局字符串加$
export function moenyFmt(money) {
  if (isNotEmpty(money)) {
    return "$ " + money
  } else {
    return money
  }
}

//全局字符串加$
export function percentFmt(percent, decimals) {
  if (isEmpty(decimals)) {
    decimals = 2
  }
  if (isNotEmpty(percent)) {
    let percent_db = parseFloat(percent)
    return (percent_db * 100).toFixed(decimals) + " %"
  } else {
    return percent
  }
}


//js 小数相加精确结果
export function accAdd(arg1, arg2) {
  if (isNaN(arg1)) {
    arg1 = 0;
  }
  if (isNaN(arg2)) {
    arg2 = 0;
  }
  arg1 = Number(arg1);
  arg2 = Number(arg2);
  var r1, r2, m, c;
  try {
    r1 = arg1.toString().split(".")[1].length;
  }
  catch (e) {
    r1 = 0;
  }
  try {
    r2 = arg2.toString().split(".")[1].length;
  }
  catch (e) {
    r2 = 0;
  }
  c = Math.abs(r1 - r2);
  m = Math.pow(10, Math.max(r1, r2));
  if (c > 0) {
    var cm = Math.pow(10, c);
    if (r1 > r2) {
      arg1 = Number(arg1.toString().replace(".", ""));
      arg2 = Number(arg2.toString().replace(".", "")) * cm;
    } else {
      arg1 = Number(arg1.toString().replace(".", "")) * cm;
      arg2 = Number(arg2.toString().replace(".", ""));
    }
  } else {
    arg1 = Number(arg1.toString().replace(".", ""));
    arg2 = Number(arg2.toString().replace(".", ""));
  }
  return (arg1 + arg2) / m;
}