<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script setup name="App">
import { onMounted } from 'vue'
import { setThemeColor } from '@/utils/style'

onMounted(() => {
  //setThemeColor('red')
})
</script>

<style>
body {
  margin: 0;
  padding: 0;
}

.cursor-pointer {
  cursor: pointer;
}
</style>
