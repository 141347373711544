import { createApp } from 'vue'
import App from './App.vue'
import router from './route'
import ElementPlus from 'element-plus'
import Antd from 'ant-design-vue';
import store from './store'

import * as ELIcons from '@element-plus/icons-vue'

// 重置浏览器css样式
import '@/assets/css/reset.css'

import 'ant-design-vue/dist/antd.css';
import 'element-plus/dist/index.css'
import 'default-passive-events'
//引入bootstrap 5
import '@/assets/css/bootstrap.min.css'

import "@/permission"; // permission control

import { NumericTothreeDigit, isEmpty, isNotEmpty, cloneDeepObject, moenyFmt, percentFmt } from "@/utils/util.js";

//fontawesome
import FontAwesomeIcon from "@/utils/fontawesome-icons.ts";

document.title = 'homelegance'
const app = createApp(App)
for (let iconName in ELIcons) {
    app.component(iconName, ELIcons[iconName])
}
app.use(router)
app.use(ElementPlus)
app.use(Antd)
app.use(store)

app.config.globalProperties.$filters = {
    NumericTothreeDigit(threeDigitNumeric, decimals) {
        return NumericTothreeDigit(threeDigitNumeric, decimals)
    },
    moenyFmt(value) { return moenyFmt(value) },
    percentFmt(value, decimals) { return percentFmt(value, decimals) },
}
// font-awesome
app.component("font-awesome-icon", FontAwesomeIcon)
app.mount('#app');